export default {
  ENVIRONMENT: process.env.ENVIRONMENT!,
  IGNORE_ENVIRONMENT_INDICATOR:
    process.env.IGNORE_ENVIRONMENT_INDICATOR === "true",
  REST_API_URL: process.env.REST_API_URL!,
  NEXT_APP_DOMAIN: process.env.NEXT_APP_DOMAIN!,
  NEXT_HERE_API_KEY: process.env.NEXT_HERE_API_KEY!,
  NEXT_OKTA_CLIENT_ID: process.env.NEXT_OKTA_CLIENT_ID!,
  NEXT_OKTA_ISSUER: process.env.NEXT_OKTA_ISSUER!,
  NEXT_OKTA_ENABLED: process.env.NEXT_OKTA_ENABLED === "true",
  JDBC_URL: process.env.JDBC_URL!,
};
