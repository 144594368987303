import { isEqual, isPlainObject, range } from "lodash";

/**
 * @see https://github.com/TanStack/query/blob/78ace08a6c9eedee1db31ca100fc582d91eba3f2/packages/query-core/src/utils.ts#L276-L278
 * @param value
 */
function isPlainArray(
  value: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): value is any[] {
  return Array.isArray(value) && value.length === Object.keys(value).length;
}

/**
 * Returns true if the specified object contains any circular references which point to top-level keys
 * @param obj
 */
export function hasTopLevelCircularReferences(obj: unknown): boolean {
  if (typeof obj !== "object" || !obj) return false;

  const seen = new Set<unknown>();

  const visit = (value: unknown) => {
    if (typeof value !== "object" || !value) return;
    if (seen.has(value)) return;
    seen.add(value);
    Object.values(value).forEach((v) => visit(v));
  };

  const values = Object.values(obj);

  values.forEach((value) => {
    if (typeof value !== "object" || !value) return;
    Object.values(value).forEach((v) => visit(v));
  });

  return values.some((value) => seen.has(value));
}

/**
 * Similar to `@tanstack/react-query`'s own `structuralSharing` function, but modified to work with circular references
 * @see https://github.com/TanStack/query/blob/78ace08a6c9eedee1db31ca100fc582d91eba3f2/packages/query-core/src/utils.ts#L210-L254
 *
 * @param oldData
 * @param newData
 */
export default function structuralSharing<T>(
  oldData: T | undefined,
  newData: T,
): T;
export default function structuralSharing(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldData: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newData: any,
): unknown {
  if (oldData === newData) return oldData;

  const array = isPlainArray(oldData) && isPlainArray(newData);

  if (array || (isPlainObject(oldData) && isPlainObject(newData))) {
    const oldItems = array ? oldData : Object.keys(oldData as object);
    const oldSize = oldItems.length;
    const newItems = array ? newData : Object.keys(newData as object);
    const newSize = newItems.length;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copy: any = array ? [] : {};

    let equalItems = 0;

    range(newSize).forEach((i) => {
      const key = array ? i : newItems[i];
      if (
        !array &&
        oldData[key] === undefined &&
        newData[key] === undefined &&
        oldItems.includes(key)
      ) {
        copy[key] = undefined;
        // eslint-disable-next-line no-plusplus
        equalItems++;
      } else if (hasTopLevelCircularReferences(newData[key])) {
        // If there are circular references we can't recurse into the object or we'll be stuck in an
        // infinite loop. Instead, we use `structuredClone` if we need to copy, but we're still able
        // to reuse the entire object from `oldData` if it is identical
        copy[key] = structuredClone(newData[key]);
        if (isEqual(copy[key], oldData[key])) {
          // eslint-disable-next-line no-plusplus
          equalItems++;
        }
      } else {
        copy[key] = structuralSharing(oldData[key], newData[key]);
        if (copy[key] === oldData[key] && oldData[key] !== undefined) {
          // eslint-disable-next-line no-plusplus
          equalItems++;
        }
      }
    });

    return oldSize === newSize && equalItems === oldSize ? oldData : copy;
  }

  return newData;
}
